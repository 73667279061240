<template>
  <b-overlay v-if="!completed" :show="loading" rounded="sm" no-fade>
    <div class="container text-center pl-5 pr-5">
      <p style="font-size: 3em; font-weight: 700; line-height: 1.2em;" class="text-success">Get Your Excel Sorted</p>
      <p style="font-size: 1.5em; line-height: 1.2em;">Tap the buttons below and tell us a little bit about your problems, and we'll show you how our bespoke solutions can solve them.</p>

      <p style="font-size: 1.5em; line-height: 1.2em;">Whilst we are Excel fanatics, please note that we are a paid for service and not part of Microsoft’s customer service team.</p>
      <hr />
    </div>

    <div>
      <form-wizard color="#1D6F42" :title="null" :subtitle="null" shape="square" finish-button-text="Submit" back-button-text="Previous" class="mb-3" @on-complete="formSubmitted">
        <!-- accoint details tab -->
        <tab-content title="What do you need help with?" :before-change="validationForm">
          <!-- <h1 class="mt-2">What do you need help with?</h1> -->

          <div class="row mt-2">
            <div class="col-md-6 col-lg-6">
              <b-button style="border-radius: 50px;" @click="selectedButton = '1'" :class="selectedButton == 1 ? 'bg-success' : 'reason'" block variant="relief-warning mb-1">
                Help with an Excel sheet
              </b-button>
            </div>
            <div class="col-md-6 col-lg-6">
              <b-button style="border-radius: 50px;" @click="selectedButton = '2'" :class="selectedButton == 2 ? 'bg-success' : 'reason'" block variant="relief-warning mb-1">
                I need to create a new sheet
              </b-button>
            </div>
            <div class="col-md-6 col-lg-6">
              <b-button style="border-radius: 50px;" @click="selectedButton = '3'" :class="selectedButton == 3 ? 'bg-success' : 'reason'" block variant="relief-warning mb-1">
                I need a VBA Macro
              </b-button>
            </div>
            <div class="col-md-6 col-lg-6">
              <b-button style="border-radius: 50px;" @click="selectedButton = '4'" :class="selectedButton == 4 ? 'bg-success' : 'reason'" block variant="relief-warning mb-1">
                I need an Excel based cloud database solution
              </b-button>
            </div>
            <div class="col-md-6 col-lg-6">
              <b-button style="border-radius: 50px;" @click="selectedButton = '5'" :class="selectedButton == 5 ? 'bg-success' : 'reason'" block variant="relief-warning mb-1">
                I need ongoing Excel support
              </b-button>
            </div>
            <div class="col-md-6 col-lg-6">
              <b-button style="border-radius: 50px;" @click="selectedButton = '6'" :class="selectedButton == 6 ? 'bg-success' : 'reason'" block variant="relief-warning mb-1">
                Other
              </b-button>
            </div>
          </div>
        </tab-content>

        <!-- personal details tab -->
        <tab-content title="Relevant Files">
          <h1 class="mt-2 text-center">Any relevant file that you need to upload?</h1>

          <div class="p-5">
            <b-form-file v-model="file" type="file" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="mt-2" multiple />
          </div>
        </tab-content>

        <!-- address  -->
        <tab-content title="Contact Details" :before-change="validationFormContact">
          <h1 class="mt-2 text-center">Where can we contact you regarding your project?</h1>

          <b-row>
            <validation-observer ref="contactRules" tag="form">
              <b-row>
                <b-col md="6" xl="4" class="mb-1">
                  <validation-provider #default="{ errors }" name="Name" rules="required">
                    <b-form-input id="name" v-model="name" required placeholder="Name" />
                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col md="6" xl="4" class="mb-1">
                  <validation-provider #default="{ errors }" name="email" rules="required|email">
                    <b-form-input id="email" v-model="email" placeholder="Email address" />
                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col md="6" xl="4" class="mb-1">
                  <validation-provider #default="{ errors }" name="Phone">
                    <!-- <b-form-input
                  id="phone"
                  v-model="phoneNumber"
                  placeholder="Phone number"
                /> -->

                    <vue-tel-input v-model="phoneNumber" id="phone" mode="international" :validCharactersOnly="true" :inputOptions="vueTelOptions" :dropdownOptions="vueTelDropdownOptions" :preferredCountries="vueTelPreferredCountries" defaultCountry="GB" styleClasses="vue-tel-input"></vue-tel-input>

                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col md="6" xl="4" class="mb-1">
                  <validation-provider #default="{ errors }" name="Location" rules="required">
                    <b-form-input id="location" v-model="location" placeholder="Location" />
                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col md="12" class="mb-1">
                  <validation-provider #default="{ errors }" name="Message" rules="required">
                    <b-form-textarea id="message" v-model="message" placeholder="Message" rows="3" />
                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>

                <b-col md="12">
                  <validation-provider #default="{ errors }" name="Robot" rules="required">
                    <b-form-input id="robot" v-model="robot" placeholder="I am not a robot. What is 2*2+10=?" />
                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </b-row>
        </tab-content>
      </form-wizard>
    </div>
  </b-overlay>

  <div class="container text-center" v-else>
    <img src="https://tailormadeexcel.com/wp-content/uploads/2023/05/gtm-200x46.png" class="mb-3" />
    <p style="font-size: 2.5em; line-height: 1.5em; color: #1D6F42;">Thank you for contacting us. The form has been submitted successfully. We'll get in touch with you as soon as possible.</p>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BButton, BFormFile, BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import axiosIns from '@/libs/axios';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormFile,
    BFormGroup,
    vSelect,
    FormWizard,
    TabContent,
  },

  data() {
    return {
      percentCompleted: 0,
      selected: { title: '' },
      file: null,
      option: [{ title: '24 hours' }, { title: 'A week' }, { title: 'A month' }, { title: 'No urgency' }],
      selectedButton: null,
      name: '',
      email: '',
      phoneNumber: '',
      location: '',
      message: '',
      robot: '',
      loading: false,
      modalFile: [],
      completed: false,

      vueTelOptions: {
        maxlength: 10,
        placeholder: 'Phone number',
      },
      vueTelDropdownOptions: {
        showDialCodeInSelection: false,
        showSearchBox: false,
        showFlags: true,
        showDialCodeInList: true,
      },

      vueTelPreferredCountries: ['GB', 'US', 'CA', 'IE', 'DE', 'AU', 'NZ'],
    };
  },
  computed: {},
  methods: {
    formSubmitted() {
      this.loading = true;
      if (this.percentCompleted == 0) {
        if (this.file != null) {
          this.uploadFilesSequentially(0);
        } else {
          var data = {
            name: this.name,
            email: this.email,
            phone: this.phoneNumber,
            location: this.location,
            message: this.message,
            selectedButton: this.selectedButton,
            fileNames: [],
          };

          this.modalFileOk(data);
        }
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        if (this.selectedButton != null) {
          resolve(true);
        } else {
          this.$bvToast.toast('Please select a reason', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
          });
          reject();
        }
      });
    },

    validationFormContact() {
      return new Promise((resolve, reject) => {
        this.$refs.contactRules.validate().then((success) => {
          if (success) {
            if (this.robot == 14) {
              resolve(true);
            } else {
              this.$bvToast.toast('Please check the verification response', {
                title: 'Error',
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000,
              });
              reject();
            }
          } else {
            reject();
          }
        });
      });
    },

    uploadFilesSequentially(index) {
      if (index < this.file.length) {
        let element = this.file[index];
        let formData = new FormData();

        formData.append('file', element);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * (100 - (this.file.length + 1))) / progressEvent.total);
          }.bind(this),
        };

        axiosIns
          .post('uploadLeadFile', formData, config)
          .then((response) => {
            this.modalFile.push(response.data);
            if (index === this.file.length - 1) {
              this.percentCompleted = 100;
            }

            if (this.percentCompleted === 100) {
              var data = {
                name: this.name,
                email: this.email,
                phone: this.phoneNumber,
                location: this.location,
                message: this.message,
                selectedButton: this.selectedButton,
                fileNames: this.modalFile,
              };

              this.modalFileOk(data);
            }

            this.uploadFilesSequentially(index + 1);
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },

    modalFileOk(data) {
      axiosIns
        .post('saveContact', data)
        .then((res) => {
          this.loading = false;
          this.completed = true;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
button.reason {
  font-size: 16px;

  background: #fbdc86;
  background-image: linear-gradient(to top, #f8bf56, #fbdc86);
  border: 1px solid #c0830b;
  color: #c0830b;
}

.vue-tel-input {
  height: 37.99px;
  max-height: 37.99px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.375rem;
}
</style>

<style>
body {
  background-color: transparent !important;
}

.vti__dropdown-item .vti__flag-wrapper {
  display: inline !important;
}

.vti__dropdown-list {
  width: 366px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
